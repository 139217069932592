import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from "jquery";
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import PlayVideo from "../../Play/custom-video2";
import ShareFrndForm from "../../forms/send-friend-form"
import "./Details.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import Description from "../PropertyDescription/PropertyDescription";
const Soon = 'https://ggfx-redbrik.s3.eu-west-2.amazonaws.com/i.prod/coming_soon_large_e7f7a501b9.jpg';
// Header component

const Details = (props) => {

    // Floorplan slider
    const [isPlay, setPlay] = useState(false);
    const settings = {
      dots: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1402,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 377,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const propertyImages = props.propertyData.images
    const hasAccomodationSumm = (props.propertyData.accomadation_summary.length > 0)

    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    const floorplanImages = props.propertyData.floorplan
    // Floorplan slider

    // ggfx
    let processedImages = JSON.stringify({});
    if (props.propertyData?.imagetransforms?.images_Transforms) {
        processedImages = props.propertyData.imagetransforms.images_Transforms;
    }
    // ggfx

    // Property floorplan images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }

    var propertyLightImagesFloorplan = floorplanImages && floorplanImages.map(img => img.url);
    // Property floorplan images lightbox

    // useEffect(() => {
    //     var propertySelector = '.property-details-menu-wrapper li a';

    //     $(propertySelector).on('click', function(){
    //         $(propertySelector).removeClass('active');
    //         $(this).addClass('active');
    //     });

    //     $(".propertyFeatures").click(function() {
    //         $('html, body').animate({
    //             scrollTop: $("#key-features").offset().top-140
    //         }, 1000);
    //     })

    //     $(".propertyDetails").click(function() {
    //         $('html, body').animate({
    //             scrollTop: $("#property-details-description").offset().top-140
    //         }, 1000);
    //     })

    //     $(".propertyMap").click(function() {
    //         $('html, body').animate({
    //             scrollTop: $("#property-details-map").offset().top - 70
    //         }, 1000);
    //     })
    // },[]);

    // Social share
    const location = useLocation();

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    const [Shareicons,setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
        setShareicons(false);
        }
    }

    const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);

    const openBookViewformModal = () => {
        setBookViewingformOpen(true);
    }

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' +shareurl,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
        });
    }
    // Social share
    var img_1 = Soon;
    var img_2 = Soon;
    if(propertyImages.length) {
      img_1 = propertyImages[0].srcUrl
      img_2 = propertyImages[1] && propertyImages[1]?.url
    }

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                {hasAccomodationSumm && (
                  <section className="details-section">
                      <Container>
                          <Row>
                              <Col sm={12}>
                                  <div className="tab-content tab-content-details" id="key-features">
                                      {
                                          props.propertyData.accomadation_summary.length > 0 &&
                                          <h1 className="col-lg-3" ><i>Features &</i> Description</h1>
                                      }
                                      <div className="tab-details col-lg-6">
                                          {
                                              props.propertyData &&
                                              props.propertyData.accomadation_summary.map((keyData) => {
                                                  return (
                                                      <p>{keyData.featuresForPortals[0].attributes.name}</p>
                                                  )
                                              })
                                          }
                                      </div>
                                      <div className="floorplan-wrapper col-lg-3">
                                          <div className="second-img">
                                            <ImageTransform
                                                imagesources={img_2}
                                                renderer="srcSet"
                                                imagename="property.images.floorplan"
                                                attr={{ alt: '', class: '' }}
                                                imagetransformresult={processedImages}
                                                id={props.propertyData.id}
                                            />
                                          </div>
                                      </div>                                      
                                  </div>
                                  <Description propertyData={props.propertyData} />
 
                              </Col>
                          </Row>
                      </Container>
                  </section>
                )}
            </ScrollAnimation>

            {/* Property floorplan image lightbox */}
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImagesFloorplan[photoIndex]}
                        nextSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(photoIndex + 1) %propertyLightImagesFloorplan.length] : undefined}
                        prevSrc={floorplanImages.length > 1 ? propertyLightImagesFloorplan[(photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length] :undefined}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + propertyLightImagesFloorplan.length - 1) %propertyLightImagesFloorplan.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImagesFloorplan.length)
                    }
                />
            )}
            {/* Property floorplan image lightbox */}

        </React.Fragment>
    );
};

export default Details;
